<template>
  <div>
    <mq-layout :mq="['mobile', 'tablet']">
      <catalogue-mobile :gender="gender"></catalogue-mobile>
    </mq-layout>
    <mq-layout mq="desktop">
      <catalogue-desktop :gender="gender"></catalogue-desktop>
    </mq-layout>
  </div>
</template>

<script>
  import CatalogueDesktop from './CatalogueDesktop/CatalogueDesktop.vue'
  import CatalogueMobile  from './CatalogueMobile/CatalogueMobile.vue'

  export default {
    props: {
      gender: {
        required: true,
        type: String
      }
    },

    components: {
      CatalogueDesktop,
      CatalogueMobile
    },

    metaInfo() {
      return {
        title: this.$t('catalogue.pageTitle')
      }
    }
  }
</script>